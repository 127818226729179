/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
// import homeImag1 from "../assets/misc images/aromatherapy_Panda.jpg";
// import homeImag2 from "../assets/Diffusers/lavender-oil.jpg";
// import homeImag3 from "../assets/smuding-sage-and-bowl/Rose-Petals Blue-Sage.jpg";
// import homeImag4 from "../assets/smuding-sage-and-bowl/Dragons_Blood_Smudge_Bundle.jpg";
// import homeImag5 from "../assets/smuding-sage-and-bowl/Sage and Bowl.jpeg";
// import homeImag6 from "../assets/misc images/BodyOils-FP.png";
// import homeImag7 from "../assets/Diffusers/Diffusers.jpg";
import homeImag8 from "../assets/smuding-sage-and-bowl/Smudging-with-Sage.jpg";

import { FaFacebookF, FaYoutube } from "react-icons/fa";
import api from "../Axios/AxiosInterceptor";
import axios from "axios";
const WholeSal = () => {
  const [headerText, setHeaderText] = useState(""); // State to store the API data
  const [topHeaderText, settopHeaderText] = useState(""); // State to store the API data
  const [image_text, setimage_text] = useState("");
  const [sect2WholeTittle, setsect2WholeTittle] = useState(""); // State to store the API data
  const [contentTxt, contentTxtS2] = useState(""); // State to store the API data
  // const [showMainbgImage, setMainBgImage] = useState([]); // State to store the API data
  const [downheading, setDownHeading] = useState("");
  const [headingws2, headingWs2] = useState("");
  const [showimage, setshowimage] = useState([]);
  const [showMainbgImage, setMainBgImage] = useState("");

  // Fetch Background Image URL from API
  const fetchBackgroundImage = async () => {
    try {
      const response = await fetch(
        "https://puregoldapi.dev-mn.xyz/api/admin/get/about/bg"
      ); // Replace with your API URL
      const data = await response.json();
      setMainBgImage(data?.data?.file_path); // Assuming your API response contains an "imageUrl" field
      console.log("bgimage", data?.data?.file_path);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }
  };

  useEffect(() => {
    fetchBackgroundImage();
  }, []);
  console.log("showImage", showimage);
  const fetchHeaderData = async () => {
    try {
      const response = await axios.get(
        "https://puregoldapi.dev-mn.xyz/api/admin/get/about/page/section/one"
      );
      if (response) {
        setHeaderText(response?.data?.data[0]?.title); // Assuming `message` contains the text
        setimage_text(response?.data?.data[0]?.image_text); // Assuming `message` contains the text
        setshowimage(response?.data?.data[0]?.images); // Assuming `message` contains the text
        // console.log(response.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  };
  const fetchDatasection2W = async () => {
    try {
      const response = await axios.get(
        "https://puregoldapi.dev-mn.xyz/api/admin/get/about/page/section/two"
      );
      if (response) {
        setsect2WholeTittle(response?.data?.data?.title); // Assuming `message` contains the text
        contentTxtS2(response?.data?.data?.content_text); // Assuming `message` contains the text
        setDownHeading(response?.data?.data?.down_heading); // Assuming `message` contains the text
        headingWs2(response?.data?.data?.heading); // Assuming `message` contains the text
        console.log("sssdccc", response.data);
      }
    } catch (error) {
      console.error("Error fetching header data:", error);
    }
  };

  useEffect(() => {
    fetchHeaderData();
    fetchDatasection2W();

    api
      .get("https://puregoldapi.dev-mn.xyz/api/admin/get/about/page/top/header")
      .then((res) => {
        settopHeaderText(res.data.data.title);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundImage: `url(${homeImag8})`,
        }}
        className="mb-0"
      >
        <div
          className="w-full"
          style={{
            padding: "16px",
            textAlign: "center",
            backgroundColor: "#693948",
          }}
        >
          <p style={{ color: "white", margin: 0 }}>{topHeaderText}</p>
        </div>
        <div className="relative flex items-center justify-center">
          <div
            className="relative mt-[84px] ml-[122px] flex flex-row flex-wrap"
            style={{ position: "relative" }}
          >
            {/* Images */}
            {showimage?.map((img) => {
              return (
                <img
                  style={{ width: "500px", height: "332px" }}
                  src={img?.file_path}
                  alt=""
                />
              );
            })}
            <div
              style={{
                zIndex: "33",
                marginLeft: "608px",
                width: "394px",
                padding: "22px",
                backgroundColor: "#68ADC2",
              }}
            >
              <p
                style={{ fontSize: "32px", color: "white", fontWeight: "bold" }}
              >
                {headerText}
              </p>
            </div>
            <div
              style={{
                position: "absolute",
                top: "28%", // Adjust vertical positioning
                left: "40%", // Center horizontally
                transform: "translate(-50%, -50%)", // Center perfectly
                zIndex: 10, // Ensure it's above images
                textAlign: "center",
              }}
            >
              <h2
                style={{
                  fontSize: "64px",
                  fontWeight: "bold",
                  color: "#E36A63",
                }}
              >
                {image_text}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="relative    py-7 px-4">
        <div className="absolute inset-0 z-0"></div>
      </div>
      <div
        className="w-full"
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundImage: `url(${showMainbgImage})`,
        }}
      >
        <div className="relative py-12">
          <div className="max-w-6xl mx-auto px-4 grid md:grid-cols-2 gap-8 items-start">
            {/* Welcome Text */}
            <div className="bg-[#603F58] ml-[55px] opacity-90 mt-[33px] text-[18px] w-[994px] backdrop-blur p-12 text-white">
              <h1 className="text-5xl md:text-[53px] font-bold text-white text-center tracking-wide">
                {sect2WholeTittle}
              </h1>
              <h1
                className="text-pink-300"
                style={{
                  fontSize: "38px",
                  marginTop: "18px",
                  fontWeight: "bold",
                }}
              >
                {headingws2}
              </h1>
              <p
                style={{ wordSpacing: "2px" }}
                className="text-[18px] leading-[38px] pt-[26px] pb-[26px] mx-[7px]"
              >
                {contentTxt}
              </p>
              <div className="text-center">
                <p style={{ backgroundColor: "#693948", padding: "15px" }}>
                  {" "}
                  {downheading}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-[#6E8787] text-white py-10">
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex space-x-6 mb-4">
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>
          </div>
          <p className="text-lg font-semibold">Pure Gold Fragrances</p>
          <div className="flex space-x-8 mt-4">
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Home
            </a>
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Wholesale
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WholeSal;
