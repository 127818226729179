import React, { useContext, useEffect, useState } from "react";
import { Navbar, Dropdown } from "flowbite-react";
import { Link } from "react-router-dom";
import Logo from "../assets/misc images/perfumeLogo.png";
import { LuBaggageClaim } from "react-icons/lu";
// import api from "../Axios/AxiosInterceptor";
import axios from "axios";
import CartModal from "./Modal/ModalForCart";
import { ThemeContext } from "../context/CardContext";

const Header = () => {
  const { carts } = useContext(ThemeContext);
  const [categories, setCategories] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const handleClose = () => setshowmodal(false);
  const handleShow = () => setshowmodal(true);

  useEffect(() => {
    axios
      .get("https://puregoldapi.dev-mn.xyz/api/user/get/all/categories")
      .then((res) => {
        setCategories(res?.data?.data);
        console.log("getCatagory", res?.data?.data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
      });
  }, []);

  return (
    <div className="p-[7px] bg-[#29444D] text-white pl-12 pr-20">
      <Navbar className="bg-[#29444D] text-white" fluid rounded>
        <Navbar.Brand as={Link} href="/">
          <img
            src={Logo}
            className="mr-3 w-[256px] sm:h-[54px]"
            alt="Flowbite React Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link className="text-[17px] text-[#F6A5AA] cursor-pointer">
            <Link to={"/"}>Home</Link>
          </Navbar.Link>
          <Navbar.Link
            className="text-[17px] text-white cursor-pointer"
            as={Link}
            href="#"
          >
            <Link to={"/whole_Sale"}>Wholesale</Link>
          </Navbar.Link>
          {/* <Dropdown label="Our Products" inline> */}
          <Dropdown label="Our Products" inline>
            {categories.map((category) => (
              <Dropdown.Item
                key={category.id}
                as={Link}
                to={`/category/${category.id}`}
              >
                {category.name}
              </Dropdown.Item>
            ))}
          </Dropdown>
          <div onClick={() => handleShow()} className="flex relative h-[22px]">
            <LuBaggageClaim size={20} />
            <p className="absolute left-5" style={{ top: "-9px" }}>
              {carts?.length || 0}
            </p>
          </div>

          {/* <Dropdown.Item href="/our_products">Diffuser Oils</Dropdown.Item>
            <Dropdown.Item href="/our_products">Health Care</Dropdown.Item>
            <Dropdown.Item href="/our_products">Incense-Accessories</Dropdown.Item> */}
          {/* </Dropdown> */}
        </Navbar.Collapse>
      </Navbar>
      <CartModal showmodal={showmodal} handleClose={handleClose} />
    </div>
  );
};

export default Header;
