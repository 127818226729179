import React, { createContext, useState } from 'react'

 export const ThemeContext = createContext();


 export const ThemeProvider = ({ children }) => {
    const [carts, setcarts] = useState([]);


    // const toggleTheme = () => setIsDarkTheme(prevTheme => !prevTheme);


    return (
        <ThemeContext.Provider value={{carts,setcarts }}>
            {children}
        </ThemeContext.Provider>
    )
}

