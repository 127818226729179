/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {  useEffect, useState } from "react";
// import homeImag1 from "../assets/misc images/aromatherapy_Panda.jpg";
// import homeImag2 from "../assets/Diffusers/lavender-oil.jpg";
// import homeImag3 from "../assets/smuding-sage-and-bowl/Rose-Petals Blue-Sage.jpg";
// import homeImag4 from "../assets/smuding-sage-and-bowl/Dragons_Blood_Smudge_Bundle.jpg";
// import homeImag5 from "../assets/smuding-sage-and-bowl/Sage and Bowl.jpeg";
// import homeImag6 from "../assets/misc images/BodyOils-FP.png";
// import homeImag7 from "../assets/Diffusers/Diffusers.jpg";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import api from "../Axios/AxiosInterceptor";
// import { ThemeContext } from "../context/CardContext";

const Home = () => {
  // const products = [
  //   {
  //     title: "Body Oils",
  //     image: homeImag6, // Replace with your image path
  //     bgColor: "text-pink-500",
  //   },
  //   {
  //     title: "Incense/Accessories",
  //     image: homeImag7, // Replace with your image path
  //     bgColor: "text-pink-500",
  //   },
  //   {
  //     title: "Diffusers-Oils",
  //     description: "Buy 5 Get One Free. Lamps 1 for $15 and 2 for $25",
  //     image: homeImag2, // Replace with your image path
  //     bgColor: "text-red-500",
  //   },
  //   {
  //     title: "Health Care",
  //     description: "Click on the image to browse our selection.",
  //     image: homeImag4, // Replace with your image path
  //     bgColor: "text-green-500",
  //   },
  // ];
  const [sections, setSections] = useState({
    section1: [],
    section2: [],
    section3: [],
    section4: [],
    section5: [],
    section6: [],
    section7: [],
  });

  const [loader, setLoader] = useState(false);

  const fetchAllSections = async () => {
    setLoader(true);
    try {
      const [res1, res2, res3, res4, res5, res6, res7] = await Promise.all([
        api.get("admin/get/home/page/section/one"),
        api.get("admin/get/home/page/section/two"),
        api.get("admin/get/home/page/section/three"),
        api.get("admin/get/home/page/section/four"),
        api.get("admin/get/home/page/section/five"),
        api.get("admin/get/home/page/section/six"),
        api.get("admin/get/home/page/section/seven"),
      ]);
      setSections({
        section1: res1.data,
        section2: res2.data,
        section3: res3.data,
        section4: res4.data,
        section5: res5.data,
        section6: res6.data,
        section7: res7.data,
      });
    } catch (error) {
      console.error("Failed to fetch sections:", error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchAllSections();
  }, []);

  if (loader) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div className="h-[400px]">
        <div
          className=""
          style={{
            padding: "16px",
            textAlign: "center",
            backgroundColor: "#693948",
          }}
        >
          <p style={{ color: "white", margin: 0 }}>
            {sections.section1.length !== 0 &&
              sections.section1.data[0]?.below_top_heading}
          </p>
        </div>
        <div className="mb-0">
          <div className="relative flex items-center justify-center">
            <div className="absolute inset-0 z-0 h-[400px]">
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                {sections.section1.length !== 0 &&
                  sections.section1.data[0]?.images.map((i) => (
                    <SwiperSlide>
                      <img src={i.file_path} alt="not ok"/>
                    </SwiperSlide>
                  ))}
              </Swiper>
              <div className="absolute inset-0" />
            </div>

            <div className="relative z-10 mt-12  text-center">
              <div className="bg-red-500/80 text-white py-6 px-4">
                <p className="text-xl md:text-2xl max-w-4xl mx-auto leading-relaxed">
                  {sections.section1.length !== 0 &&
                    sections.section1.data[0]?.title}
                </p>
              </div>
              <div className="bg-slate-800/60 backdrop-blur py-8 px-4">
                <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-center gap-4">
                  <h2 className="text-4xl md:text-5xl font-bold text-amber-400">
                    PURE GOLD
                  </h2>
                  <div className="text-2xl md:text-3xl text-white">
                    <span className="font-light">Fragrance</span>
                    <span className="ml-2 font-light">Experience</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bg-[#113E4D]  py-7 px-4">
        <div className="absolute inset-0 z-0"></div>

        <div className="relative z-10 max-w-7xl mx-auto text-center space-y-4">
          <h1 className="text-4xl md:text-5xl font-bold text-white tracking-wide">
            {sections.section2 && sections.section2.data?.heading}
          </h1>
          <p className="text-3xl md:text-4xl font-medium text-pink-300">
            {sections.section2 && sections.section2.data?.title}
          </p>
        </div>
      </div>
      <div className="relative bg-gradient-to-b from-lime-200/40 to-lime-100/40 py-12">
        <div className="max-w-6xl mx-auto px-4 grid md:grid-cols-2 gap-8 items-start">
          <div className="bg-[#113E4D]/80 w-[444px] backdrop-blur p-8 text-white">
            <p
              style={{ wordSpacing: "10px" }}
              className="text-base leading-[38px] pt-[26px] pb-[26px] mx-[7px]"
            >
              {sections.section3 && sections.section3.data?.content}
            </p>
          </div>
          <div className="space-y-6">
            <div className="bg-slate-900/90 p-4 rounded-lg">
              <div className="relative pb-[56.25%] h-0">
                <iframe
                  className="absolute top-0 left-0 w-full h-full rounded-lg"
                  src={sections.section3.data?.video_links}
                  title="15 Amazing Benefits And Uses Of Frankincense"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div
              style={{ marginTop: "-11px", borderRadius: "0px" }}
              className=" "
            >
              <img
                src={sections.section3 && sections.section3.data?.file_path}
                alt="Essential Oils with Lavender"
                className="w-full h-[333px] object-cover "
              />
            </div>
          </div>
        </div>
        <div class="bg-white container flex flex-row  mx-auto px-[66px] py-[124px]">
          <div>
            <h1
              style={{ lineHeight: "63px" }}
              className="w-[333px] text-4xl md:text-6xl font-bold"
            >
              {sections.section4 && sections.section4.data?.title}
            </h1>
          </div>
          <div class="w-full">
            <iframe
              style={{ marginLeft: "246px", width: "535px", height: "323px" }}
              class="w-full h-[270px] md:h-[360px] rounded-lg shadow-lg"
              src={sections.section4.data?.video_links}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div
          style={{ lineHeight: "93px" }}
          class="bg-[#F77E8C] mx-[144px] -mt-[22px] p-[58px] rounded-lg shadow-lg"
        >
          <p class=" text-white text-lg leading-[44px] mb-6">
            {sections.section4 && sections.section4.data?.content}
          </p>
        </div>
        <div
          style={{ gap: "27px" }}
          className="bg-white pl-[124px] pt-[83px] p-[55px] mt-[84px] flex flex-row flex-wrap"
        >
          {sections.section5 &&
            sections.section5.data?.map((d) => (
              <img
                style={{ width: "320px", height: "222px" }}
                key={d.id}
                src={d.file_path}
                alt=""
              />
            ))}
        </div>
        <div class="relative bg-green-100 py-12 px-6">
          <h1 class="text-4xl md:text-5xl font-bold text-center text-red-800 mb-8">
            {sections.section6 && sections.section6.data?.title}
          </h1>
          <p class="text-lg md:text-[24px] px-[77px] text-gray-800 leading-snug mb-8">
            {sections.section6 && sections.section6.data?.content_text}
          </p>

          <div class="absolute bottom-0 right-0 w-1/3 md:w-1/4"></div>
        </div>
        <section className="bg-white py-10">
          <div className="max-w-7xl mx-[93px] grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h2
                className={`mt-4 text-[38px] ml-[83px] mb-[33px] font-semibold text-pink-500`}
              >
                {sections.section7 && sections.section7.data?.image1_text}
              </h2>
              <img
                src={sections.section7 && sections.section7.data?.file_path1}
                alt="image"
                className="w-[448px] h-[336px] object-cover "
              />
              <p className="mt-5 ml-9 text-gray-600"></p>
            </div>
            <div>
              <h2
                className={`mt-4 text-[38px] ml-[83px] mb-[33px] font-semibold text-pink-500`}
              >
                {sections.section7 && sections.section7.data?.image2_text}
              </h2>
              <img
                src={sections.section7 && sections.section7.data?.file_path2}
                alt="image"
                className="w-[448px] h-[336px] object-cover "
              />
              <p className="mt-5 ml-9 text-gray-600"></p>
            </div>
            <div>
              <h2
                className={`mt-4 text-[38px] ml-[83px] mb-[33px] font-semibold text-pink-500`}
              >
                {sections.section7 && sections.section7.data?.image3_text}
              </h2>
              <img
                src={sections.section7 && sections.section7.data?.file_path3}
                alt="image"
                className="w-[448px] h-[336px] object-cover "
              />
              <p className="mt-5 ml-9 text-gray-600"></p>
            </div>
            <div>
              <h2
                className={`mt-4 text-[38px] ml-[83px] mb-[33px] font-semibold text-pink-500`}
              >
                {sections.section7 && sections.section7.data?.image4_text}
              </h2>
              <img
                src={sections.section7 && sections.section7.data?.file_path4}
                alt="image"
                className="w-[448px] h-[336px] object-cover "
              />
              <p className="mt-5 ml-9 text-gray-600"></p>
            </div>
          </div>
        </section>
      </div>
      <footer className="bg-[#6E8787] text-white py-10">
        <div className="container mx-auto flex flex-col items-center">
          <div className="flex space-x-6 mb-4">
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="#"
              className="text-white text-3xl hover:text-gray-300"
              aria-label="YouTube"
            >
              <FaYoutube />
            </a>
          </div>
          <p className="text-lg font-semibold">Pure Gold Fragrances</p>
          <div className="flex space-x-8 mt-4">
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Home
            </a>
            <a href="#" className="text-sm text-white hover:text-gray-300">
              Wholesale
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
