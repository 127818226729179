import axios from "axios";

const api = axios.create({
  baseURL: "https://puregoldapi.dev-mn.xyz/api/",
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Corrected to 'Bearer' instead of 'barer'
    }
    return config;
  },
  (err) => {
    console.error("Request error:", err); // Log the error before rejecting
    return Promise.reject(err); // Reject promise after logging
  }
);

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (err) => {
//     console.error("Response error:", err); // Log the error before rejecting
//     return Promise.reject(err); // Reject promise after logging
//   }
// );

export default api;
