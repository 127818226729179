import React, { useState, useContext } from "react";
import { ThemeContext } from "../../context/CardContext";
import toast from "react-hot-toast";
import axios from "axios";

const CartModal = ({ showmodal, handleClose }) => {
  const { carts, setcarts } = useContext(ThemeContext);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);

  const calculateTotal = () =>
    carts.reduce(
      (total, item) =>
        total +
        item.variations.reduce(
          (variationTotal, variation) =>
            variationTotal + variation.avail * variation.price,
          0
        ),
      0
    );

  if (!showmodal) return null;

  const handleCheckoutClick = () => {
    setShowCheckoutForm(true);
  };

  const handleBackToCart = () => {
    setShowCheckoutForm(false);
  };

  const handleQuantityChange = (variationId, change) => {
    setcarts((prevCarts) =>
      prevCarts.map((item) => {
        if (item.variations) {
          return {
            ...item,
            variations: item.variations.map((variation) =>
              variation.id === variationId
                ? {
                    ...variation,
                    avail: Math.max(1, variation.avail + change), // Update avail, ensuring it's at least 1
                  }
                : variation
            ),
          };
        }
        return item;
      })
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    carts?.forEach((e, index) => {
      formData.append(`products[${index}][product_id]`, e?.id);
      e?.variations?.forEach((e) => {
        formData.append(`products[${index}][quantity]`, e?.avail===0?1:e?.avail);
        formData.append(`products[${index}][variation_id]`, e?.id);
      });
    });

    axios
      .post("https://puregoldapi.dev-mn.xyz/api/user/check/out/post", formData)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          console.log(res.data.message,'message--->');
          toast.success(res.data.message);
          setShowCheckoutForm(false);
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
        setShowCheckoutForm(false);
        if (err.response.data.message?.map(e => e === "Product  Voluptas qui eum has low stock")) {
          toast((t) => (
            <span>
              ⚠️<b style={{fontSize:'12px'}}>Product Voluptas qui eum has low stock</b>
            </span>
          ));
        } else if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message); // For other error messages
        } else {
          alert("An unexpected error occurred.");
        }
        console.log(
          "Error in POST API:",
          err.response ? err.response.data.message : err.message
        );
      });
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-full max-w-4xl rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-2xl font-semibold text-black">
            {showCheckoutForm ? "Checkout" : "Variations"}
          </h2>
          <button
            onClick={handleClose}
            className="text-gray-600 hover:text-gray-900 font-semibold mt-5"
          >
            &times;
          </button>
        </div>

        {showCheckoutForm ? (
          <div className="mt-4">
            <form className="space-y-4" onSubmit={handleFormSubmit}>
              <div>
                <label className="block mb-1 font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full border text-black border-gray-300 rounded-lg p-3 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full border text-black border-gray-300 rounded-lg p-3 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-700">
                  Phone
                </label>
                <input
                  type="tel"
                  placeholder="Enter your phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full border text-black border-gray-300 rounded-lg p-3 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="flex justify-between items-center mt-4">
                <button
                  type="button"
                  onClick={handleBackToCart}
                  className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
                >
                  Back to Cart
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
                >
                  {loading ? "Loading..." : "Confirm Checkout"}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="overflow-y-auto max-h-80 mt-4">
            {carts.length > 0 ? (
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-2 text-left text-black">
                      Product Id
                    </th>
                    <th className="px-4 py-2 text-left text-black">
                      Variations Id
                    </th>
                    <th className="px-4 py-2 text-left text-black">
                      Variations Name
                    </th>
                    <th className="px-4 py-2 text-left text-black">Quantity</th>
                    <th className="px-4 py-2 text-left text-black">Price</th>
                    <th className="px-4 py-2 text-left text-black">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {carts?.map((e) =>
                    e?.variations?.map((item, index) => (
                      <tr key={item.id} className="border-b">
                        <td className="px-4 py-2 text-black">{item?.product_id}</td>
                        <td className="px-4 py-2 text-black">{item?.id}</td>
                        
                        <td className="px-4 py-2 text-black">
                          {item.value}
                        </td>
                        <td className="px-4 py-2 text-black flex items-center">
                          <button
                            onClick={() => handleQuantityChange(item.id, -1)} // Decrease avail
                            className="bg-gray-300 text-black py-1 px-2 rounded hover:bg-gray-400"
                          >
                            -
                          </button>
                          <span className="mx-2">{item?.avail===0?1:item?.avail}</span>
                          <button
                            onClick={() => handleQuantityChange(item.id, 1)} // Increase avail
                            className="bg-gray-300 text-black py-1 px-2 rounded hover:bg-gray-400"
                          >
                            +
                          </button>
                        </td>
                        <td className="px-4 py-2 text-black">
                          ${item.price}
                        </td>
                        <td className="px-4 py-2 text-black">
                          ${item.avail * item.price}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
              <p className="text-center py-4">Your cart is empty.</p>
            )}
          </div>
        )}

        {!showCheckoutForm && (
          <div className="flex justify-between items-center border-t pt-4 mt-4">
            <h5 className="font-semibold text-lg">
              Total: ${calculateTotal()}
            </h5>
            <div>
              <button
                onClick={handleClose}
                className="bg-gray-500 text-white py-2 px-4 rounded-lg mr-2 hover:bg-gray-600"
              >
                Continue Shopping
              </button>
              <button
                onClick={handleCheckoutClick}
                className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
              >
                Checkout
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartModal;
